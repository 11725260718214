import GoogleMapsLoader from 'google-maps';

GoogleMapsLoader.KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;
GoogleMapsLoader.VERSION = 3.37;
GoogleMapsLoader.LIBRARIES = ['places'];

export const loadMaps = () => {
  return new Promise((resolve,reject) => {
    GoogleMapsLoader.load(google => {
      resolve(google);
    });
  })
}

export const pointInPolygon = (point, vs) => {
  // https://github.com/substack/point-in-polygon/blob/master/index.js
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
  
  var x = point[0], y = point[1];
  
  var inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      var xi = vs[i][0], yi = vs[i][1];
      var xj = vs[j][0], yj = vs[j][1];
      
      var intersect = ((yi > y) !== (yj > y))
          && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
  }
  
  return inside;
}