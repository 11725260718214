import React from 'react';

import LiveDate from './live_date';

class ObservationCardBody extends React.PureComponent {
  render(){
    const {observation} = this.props;

    return (
      <div>
        <div className="row mb-2">
          <div className="col">
            <h6>Validator</h6>
            <code className="mb-2">{JSON.stringify(observation.observer_type)} - {JSON.stringify(observation.observer_id)}</code>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <h6>Validated</h6>
            <code className="mb-2">{JSON.stringify(observation.validated)} - <LiveDate date={observation.validated_at} /></code>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <h6>Input</h6>
            <code className="mb-2">{JSON.stringify(observation.input)}</code>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <h6>Response</h6>
            <code className="mb-2">{JSON.stringify(observation.response)}</code>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <h6>Data</h6>
            <code className="mb-2">{JSON.stringify(observation.data)}</code>
          </div>
        </div>
      </div>
    );
  }
}

export default ObservationCardBody;