import React from 'react';
import { connect } from 'react-redux';

class VisitsDataPanel extends React.Component {

  render(){
    const { visit } = this.props;

    return (
      <div className="visits-data mb-2">
        <pre>{JSON.stringify(visit.toJSON(),null,2)}</pre>
      </div>
    )
  }
}

const mapStateToProps = (state,props) => {
  const visit = state.getIn(['observations',props.match.params.id]);
  const project_visit = state.get('project_visits').filter(pv => pv.observation_id === visit.id).minBy(pv => pv.id);
  const project = state.getIn(['projects',project_visit.project_id.toString()]);
  return {
    visit: visit,
    project: project,
    changes: state.getIn(['form_changes','visits/edit']),
  };
};

const mapDispatchToProps = {
  loadForm: (data) => ({ type: 'forms.load', data }),
};

export default connect(mapStateToProps,mapDispatchToProps)(VisitsDataPanel);