import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import LiveDate from '../../shared/components/live_date';

class VisitsPayablesPanel extends React.Component {
  render(){
    const { pricing_items, payables, payments} = this.props;

    console.log(payables.toJSON());

    return (
      <div>
        <h4>Pricing Items Status</h4>
        <table className="table table-sm table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th>Condition</th>
              <th>Potential Amount</th>
              <th>Earned Amount</th>
              <th>Currency</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {
              pricing_items.toIndexedSeq().map(pi => {
                const earnedAmount = payables.reduce((sum,p) => {  
                  const a = (p.pricing_item_id === pi.id ? parseFloat(p.amount) : 0);
                  return sum + a
                },0);

                const pricingItemAmount = parseFloat(pi.amount);

                const somethingBAD = (earnedAmount !== 0 && earnedAmount !== pricingItemAmount);

                const classes = cn({
                  'table-success': (earnedAmount === pricingItemAmount),
                  'table-danger': somethingBAD 
                })

                return (
                  <tr key={pi.id} className={classes}>
                    <td>{pi.key}</td>
                    <td>{pricingItemAmount.toFixed(2)}</td>
                    <td>{earnedAmount.toFixed(2)}</td>
                    <td>{pi.currency}</td>
                    <td>{somethingBAD ? "Please notify Ryan, something is wrong" : pi.description}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>

        <h4>Payables Log</h4>
        <table className="table table-sm table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Condition</th>
              <th>Amount</th>
              <th>Currency</th>
              <th>Paid</th>
              <th>Payment Confirmation</th>
            </tr>
          </thead>
          <tbody>
            {
              payables.toIndexedSeq().map(p => {
                const pi = pricing_items.get(p.pricing_item_id.toString());
                let payment;
                if(p.payment_id){ payment = payments.get(p.payment_id.toString()) }

                const amount = parseFloat(p.amount);
                
                const classes = cn({
                  'text-success': (amount > 0),
                  'text-danger': (amount < 0) 
                })

                return (
                  <tr key={p.id} className={classes}>
                    <td>{p.id}</td>
                    <td><LiveDate date={p.created_at}/></td>
                    <td>{pi.key}</td>
                    <td>{p.amount}</td>
                    <td>{p.currency}</td>
                    <td>{(payment ? <LiveDate date={payment.created_at}/> : null)}</td>
                    <td>{(payment ? payment.vendor_batch_id : null)}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    );
  }

}

const mapStateToProps = (state,props) => {
  let pricing_items;
  let payables;
  let payments;
  const visit = state.getIn(['observations',props.match.params.id]);
  if(visit){
    pricing_items = state.get('pricing_items').filter(pi => pi.pricing_structure_id === visit.pricing_structure_id)
    payables = state.get('payables').filter(p => p.observation_id === visit.id);
    const paymentIds = payables.map(p => p.payment_id);
    payments = state.get('payments').filter(p => paymentIds.includes(p.id));
  }
  return {
    visit: visit,
    pricing_items: pricing_items,
    payables: payables,
    payments: payments,
  };
};

export default connect(mapStateToProps)(VisitsPayablesPanel);