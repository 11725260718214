
export const inlineLocalAddress = (address) => {
  if(address){
    const line1 = [address.street_number,address.street_name].filter(x => x).join(" ");
    return [line1,address.address2].filter(x => x).join(", ");
  }
}

export const inlineAddress = (address) => {
  if(address){
    return `${inlineLocalAddress(address)}, ${address.city}, ${address.state} ${address.postal_code}`;
  }
}
