import Immutable from 'immutable';

const defaults = {
  id: null,
  number: null,
  name: null,
  address: null,
  neighborhood: null,
  city: null,
  state: null,
  postal_code: null,
  country: null,
  source_id: null,
  latitude: null,
  longitude: null,
  project_id: null,
  created_at: null,
  updated_at: null,
};

class Lead extends Immutable.Record(defaults){
  
}

export default Lead;