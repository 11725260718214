import Immutable from 'immutable';

const defaults = {
  id: null,
  type: null,
  attr: null,
  error_code: null,
  validatable_id: null,
  validatable_type: null,
  validated: null,
  validated_at: null,
  data_key: null,
};

class Prevalidation extends Immutable.Record(defaults){

}

export default Prevalidation;