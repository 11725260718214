import React from 'react';

import ImageMetadataTag from './image_metadata_tag';

class ImageCoordinatesMetadata extends React.PureComponent {
  render(){
    const text = `${this.props.image.longitude}, ${this.props.image.latitude}`;
    return (
      <ImageMetadataTag text={text} bstyle="secondary"/>
    );
  }
}

export default ImageCoordinatesMetadata;