

export const includedToActions = (included,actionType='merge') => {
  const results = [];

  const grouped = {}
  included.forEach(i => {
    grouped[i.type] ? grouped[i.type].push(i) : (grouped[i.type] = [i]);
  });
  for(let resource in grouped){
    results.push({type: `${resource}.${actionType}`, data: grouped[resource]});
  }
  return results;
};

export const dispatchResourcesTo = (store) => {
  return (json) => {
    generateResourceResponse(json).forEach(store.dispatch);
  }
};

const noTransform = (resource) => resource;

export const generateResourceResponse = (json,resourceTransform=noTransform,actionType='merge') => {
  const grouped = {};

  if(json && json.data){
    let data = json.data;
    if(json.data.attributes){ data = [data]; }

    data.forEach(resource => {
      const r = resourceTransform(resource);
      const type = r.type;
      grouped[type] = grouped[type] || []
      grouped[type].push(r);
    });
  }

  if(json && json.included){
    json.included.forEach(resource => {
      const r = resourceTransform(resource);
      const type = r.type;
      grouped[type] = grouped[type] || []
      grouped[type].push(r);
    });
  }

  const actions = Object.entries(grouped).map(([type,collection]) => {
    return { type: `${type}.merge`, data: collection };
  });

  if( json && json.meta ){
    actions.push({
      type: 'page.merge',
      data: json.meta,
    });
  }
  
  return actions;
};

export const transformPrevalidations = (data_key) => {
  return (resource) => {
    if(resource.type === 'validation'){
      resource.type = 'prevalidation';
      resource.attributes.data_key = data_key
      return resource;
    } else {
      return resource;
    }
  }
};

export const errorResponseToFormErrors = (response) => {
  return response.errors.reduce((o,e) => {
    const attr = (e.meta && e.meta.attribute) || e.code.match(/\w+\.\w+\.(\w+)/)[1];
    o[attr] = e.code;
    return o;
  },{});
};
