import Store from '../reducers/store';


export default {
  index: function(intendedLocale){
    const locale = intendedLocale ? intendedLocale.split('-')[0] : 'es';
    return fetch(`https://assets.dataplor.com/categories/${locale}.json`).then(response => {
      if(response.ok){
        return response.json().then(json => {
          const data = json.reduce((o,cat) => {
            o[cat.key] = cat;
            return o;
          },{});

          Store.dispatch({type: 'business_categories.load', data: data })
        })
      } 
    })
  }
};