import Immutable from 'immutable';

const defaults = {
  id: null,
  number: null,
  name: null,
  executive_first_name: null,
  executive_last_name: null,
  executive_title: null,
  executive_gender: null,
  executive_mobile: null,
  street_number: null,
  street_name: null,
  neighborhood: null,
  city: null,
  state: null,
  postal_code: null,
  country: null,
  geocoded_location: null,
  geocoded_neighborhood: null,
  geocoded_city: null,
  geocoded_state: null,
  geocoded_postal_code: null,
  geocoded_country: null,
  email: null,
  website: null,
  phone: null,
  mobile: null,
  fax: null,
  credit_card: null,
  internet: null,
  wifi: null,
  point_of_sale: null,
  facebook: null,
  twitter: null,
  instagram: null,
  interior_photo: null,
  exterior_photo: null,
  created_at: null,
  updated_at: null,
  optional_photo: null,
  credit_card_amex: null,
  invalid_phone: null,
  invalid_email: null,
  sic_code: null,
  sic_details: null,
  phone_type: null,
  optional_photo_2: null,
  address2: null,
  credit_card_visa: null,
  credit_card_mc: null,
  credit_card_discover: null,
  credit_card_diners: null,
  gmb_interested: null,
  gmb_call_now: null,
  gmb_best_time_to_call: null,
  gmb_call_notes: null,
  monday_open: null,
  monday_close: null,
  tuesday_open: null,
  tuesday_close: null,
  wednesday_open: null,
  wednesday_close: null,
  thursday_open: null,
  thursday_close: null,
  friday_open: null,
  friday_close: null,
  saturday_open: null,
  saturday_close: null,
  sunday_open: null,
  sunday_close: null,
  location_accuracy: null,
  altitude: null,
  alitude_accuracy: null,
  heading: null,
  location_timestamp: null,
  executive_middle_name: null,
  longitude: null,
  latitude: null,
  business_category_id: null,
  business_size: null,
};

class Place extends Immutable.Record(defaults){

  inlineLocalAddress(){
    const line1 = [this.street_number,this.street_name].filter(x => x).join(" ");
    return [line1,this.address2].filter(x => x).join(", ");
  }

  inlineAddress(){
    return `${this.inlineLocalAddress()}, ${this.city}, ${this.state} ${this.postal_code}`;
  }
}

export default Place;