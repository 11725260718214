import React from 'react';
import cn from 'classnames';

import LiveDate from './live_date';

import ObservationCardBody from './observation_card_body';
// import ConsentValidationCardBody from './consent_validation_card_body';
// import PhoneValidationCardBody from './phone_validation_card_body';
// import EmailValidationCardBody from './email_validation_card_body';
// import AddressValidationCardBody from './address_validation_card_body';

class DependentCard extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {open: false};
  }

  render(){
    const { observation } = this.props;

    let body;
    switch(observation.type){
      // case 'ConsentValidation':
      //   body = <ConsentValidationCardBody {...this.props} />
      //   break;
      // case 'PhoneValidation':
      //   body = <PhoneValidationCardBody {...this.props} />
      //   break;
      // case 'EmailValidation':
      //   body = <EmailValidationCardBody {...this.props} />
      //   break;
      // case 'AddressValidation':
      //   body = <AddressValidationCardBody {...this.props} />
      //   break;
      default:
        body = <ObservationCardBody {...this.props} />
        break;
    }

    let validatedAt;
    if(observation.validated_at){
      validatedAt = <span>, validated <LiveDate date={observation.validated_at} /></span>
    }

    const cardClasses = cn({
      'border-success': observation.validated,
      'border-danger': observation.validated === false
    },'card mb-2');

    return (
      <div className={cardClasses}>
        <div className="card-header" onClick={this.toggleOpen}>
          <div className="row">
            <div className="col-6">
              <h5 className="card-title mb-0"><span className="text-monospace">{observation.id}</span> {observation.type}</h5>
            </div>
            <div className="col-6 text-right">
              <span className="small">Created <LiveDate date={observation.created_at} />{validatedAt}</span>
            </div>
          </div>
        </div>
        <div className="card-body" style={{display: (this.state.open ? null : 'none')}}>
          {body}
        </div>
      </div>
    );
  }

  toggleOpen = (e => this.setState(prevState => ({open: !prevState.open})))
}

export default DependentCard;

