import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/app';
import { init } from '@sentry/browser';

export default function(){
  // sentry
  if(process.env.REACT_APP_SENTRY_DSN){
    init({ 
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_ENV,
      release: process.env.REACT_APP_RELEASE
    });
  }

  // not yet
  // import registerServiceWorker from './registerServiceWorker';
  // registerServiceWorker();

  const render = Component => {
    return ReactDOM.render(<Component />, document.getElementById('root'));
  };

  render(App);

  if (module.hot) {
    module.hot.accept('./components/app', () => {
      // render(NextApp);
    });
  }
}
