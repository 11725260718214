import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';

import ImageWithMetadata from '../../shared/components/image_with_metadata';

const assetsServer = process.env.REACT_APP_ASSETS_SERVER;

class PlacesImagesPanel extends React.PureComponent {
  render(){

    const {place} = this.props;
      
    const rows = ['interior','exterior'].map(attr => {
      const photo = this.props.place.get(`${attr}_photo`);
      if(photo){
        const baseKey = photo.replace(/\.\w+$/,'');

        const image = this.props.images.find(i => i.role === attr);

        return (
          <div className="row" key={attr}>
            <div className="col mb-3 d-flex justify-content-center">
              <ImageWithMetadata 
                image={image}
                urlKey={baseKey} 
                assetsServer={assetsServer} 
                role={attr} 
                latitude={place.latitude}
                longitude={place.longitude}
              />
            </div>
          </div>
        );
      } else {
        return null;
      }
     
    });


    return (
      <div className="container">
        {rows}
      </div>
    );
  }
}

const mapStateToProps = (state,props) => {
  const place = state.getIn(['places',props.match.params.id]);
  const visitIds = Immutable.Set(state.get('visits').filter(v => v.place_id === place.id).map(v => v.id).toArray());
  const images = state.get('images').filter(i => visitIds.includes(i.visit_id) );
  return {
    place: place,
    images: images
  };
};

export default connect(mapStateToProps)(PlacesImagesPanel);