import Immutable from 'immutable';

const defaults = {
  id: null,
  first_name: null,
  last_name: null,
  email: null,
  number: null,
  phone: null,
  notes: null,
  created_at: null,
  updated_at: null,
  referral_code: null,
  paypal_email: null,
  dob: null,
  locale: null,
  suspended_at: null,
  training_completed_at: null,
  quiz_completed_at: null,
  ip_address: null,
  visits_count: 0,
  payable: 0,
  brazil_cpf: null,
  brazil_cnpj: null,
  region_id: null,
  agreed_to_conditions_at: null,
  opted_into_communications_at: null,
  agreed_to_contract_at: null,
  agreed_contract_md5: null,
  trial_run_completed_at: null,
  interview_completed_at: null,
  data: Immutable.Map(),
  visits_by_date: Immutable.Map(),
};

class AdminExplorer extends Immutable.Record(defaults){
  formData(){
    return new Immutable.Map(this).mergeDeep(this.data)
  }
}

export default AdminExplorer;