import Immutable from 'immutable';

const defaults = {
  id: null,
  name: null, // project name
  first_name: null,
  last_name: null,
  closed: null, // project closed
  disabled: null,  // project disabled
  training_completed_at: null,
  quiz_completed_at: null,
  project_id: null,
  explorer_id: null,
  created_at: null,
  updated_at: null,
  disabled_at: null, // explorer disabled
};

class ExplorerProject extends Immutable.Record(defaults){

}

export default ExplorerProject;