import React from 'react';
import { connect } from 'react-redux';

import ImageWithMetadata from '../../shared/components/image_with_metadata';

const assetsServer = process.env.REACT_APP_ASSETS_SERVER;

class VisitsImagesPanel extends React.Component {
  render(){
    const { visit,images } = this.props;
    const rows = images.toIndexedSeq().sortBy(i => i.id * -1 ).map(image => {
      if(image){
        return (
          <div className="row" key={image.id}>
            <div className="col mb-3 d-flex justify-content-center">
              <ImageWithMetadata 
                image={image}
                urlKey={image.key} 
                assetsServer={assetsServer} 
                role={image.role} 
                latitude={visit.latitude}
                longitude={visit.longitude}
              />
            </div>
          </div>
        );
      } else {
        return null;
      }
    });

    return (
      <div className="container">
        {rows}
      </div>
    );
  }



}

const mapStateToProps = (state,props) => {
  let images;
  const visit = state.getIn(['observations',props.match.params.id]);
  if(visit){
    images = state.get('images').filter(i => i.observation_id === visit.id);
  }
  return {
    visit: visit,
    images: images
  };
};

export default connect(mapStateToProps)(VisitsImagesPanel);