import React from 'react';
import { connect } from 'react-redux';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import geodist from 'geodist';

import { GoogleMap,Marker,Circle } from "react-google-maps";
import DuplicateMapWrapper from './duplicate_map_wrapper';

class DuplicatePlaceMap extends React.PureComponent {
  render(){
    const { original, possible_duplicate } = this.props;
    const initLat = (original.latitude + possible_duplicate.latitude) / 2;
    const initLng = (original.longitude + possible_duplicate.longitude) / 2

    const originalCoords = {lat: original.latitude,lon: original.longitude};
    const dupCoords = {lat: possible_duplicate.latitude,lon: possible_duplicate.longitude};
    const dist = geodist(originalCoords,dupCoords,{unit: 'meters'}).toFixed(2);

    return (
      <div>
        <div className="row">
          <div className="col">
            <DuplicateMapWrapper>
              <GoogleMap
                defaultZoom={17}
                defaultCenter={{ lat: initLat, lng: initLng }}
                options={{ }}
                ref={this.map}
                onBoundsChanged={this.onBoundsChanged}
                onClick={this.onClick}
              >

                <Circle
                  center={{lat: possible_duplicate.latitude, lng: possible_duplicate.longitude }}
                  radius={possible_duplicate.location_accuracy}
                  options={{fillColor: '#ffc107', fillOpacity: 0.4, strokeWeight: 0}}
                />
                <Circle
                  center={{lat: original.latitude, lng: original.longitude }}
                  radius={original.location_accuracy}
                  options={{fillColor: '#007bff', fillOpacity: 0.4, strokeWeight: 0}}
                />

                <Marker 
                  position={{lat: original.latitude, lng: original.longitude }} 
                  icon={{path: faMapMarkerAlt.icon[4], fillColor: '#007bff', fillOpacity: 0.9, anchor:{ x: 200, y: 500}, scale: 0.075}}
                />
                <Marker 
                  position={{lat: possible_duplicate.latitude, lng: possible_duplicate.longitude }} 
                  icon={{path: faMapMarkerAlt.icon[4], fillColor: '#ffc107', fillOpacity: 0.9, anchor:{ x: 200, y: 500}, scale: 0.075}}
                />

              </GoogleMap>
            </DuplicateMapWrapper>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col text-center h4">
            <strong>Distance: {dist} m</strong>
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(DuplicatePlaceMap);