import React from 'react';
import geodist from 'geodist';

import ImageMetadataTag from './image_metadata_tag';

class ImageDistanceMetadata extends React.PureComponent {
  render(){
    const {longitude,latitude,image} = this.props;
    const placeCoords = {lat: latitude, lon: longitude};
    const imgCoords = {lat: image.latitude, lon: image.longitude};
    const v = geodist(placeCoords,imgCoords,{unit: 'meters'});
    const text = `${v} m from Place`;
    
    let style;
    if(v && v < 200){
      style = 'success';
    } else {
      style = 'danger';
    }

    return (
      <ImageMetadataTag text={text} bstyle={style}/>
    );
  }
}

export default ImageDistanceMetadata;