import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';

import PlacesActions from '../actions/places_actions';

import JSONForm from '../../shared/components/json_form';

const schema = Immutable.fromJS({
  required: [
    "name",
    "city",
    "country"
  ],
  properties: {
    "name": { "type": ["string"] },
    "phone": { "type": ["string","null"], "format": "phone" },
    "mobile": { "type": ["string","null"], "format": "phone" },
    "email": { "type": ["string","null"], "format": "email" },
    "website": { "type": ["string","null"] },
    "business_category_id": { "type": ["string"] },
    "business_size": { "type": ["string","null"], "enum": ["1_10","11_25","25_100","100"] },
    "wifi": { "type": ["boolean","null"] },
    "point_of_sale": { "type": ["boolean","null"] },
    "credit_card": { "type": ["boolean","null"] },
    "credit_card_visa": { "type": ["boolean","null"] },
    "credit_card_mc": { "type": ["boolean","null"] },
    "credit_card_amex": { "type": ["boolean","null"] },
    "credit_card_discover": { "type": ["boolean","null"] },
    "credit_card_diners": { "type": ["boolean","null"] },
    "street_number": {"type": ["string","null"] },
    "street_name": { "type": ["string","null"] },
    "address2": { "type": ["string","null"] },
    "city": { "type": ["string","null"] },
    "state": { "type": ["string","null"] },
    "postal_code": { "type": ["string","null"] },
    "country": { "type": ["string","null"] },
    "executive_first_name": { "type": ["string","null"] },
    "executive_middle_name": { "type": ["string","null"] },
    "executive_last_name": { "type": ["string","null"] },
    "executive_title": { "type": ["string","null"] },
    "executive_gender": { "type": ["string","null"], "enum": ["male","female"] },
    "monday_open": { "type": ["string","null"], "pattern": "^[0-2][0-9]\\:[0-5][0-9]"},
    "monday_close": { "type": ["string","null"], "pattern": "^[0-2][0-9]\\:[0-5][0-9]"},
    "tuesday_open": { "type": ["string","null"], "pattern": "^[0-2][0-9]\\:[0-5][0-9]"},
    "tuesday_close": { "type": ["string","null"], "pattern": "^[0-2][0-9]\\:[0-5][0-9]"},
    "wednesday_open": { "type": ["string","null"], "pattern": "^[0-2][0-9]\\:[0-5][0-9]"},
    "wednesday_close": { "type": ["string","null"], "pattern": "^[0-2][0-9]\\:[0-5][0-9]"},
    "thursday_open": { "type": ["string","null"], "pattern": "^[0-2][0-9]\\:[0-5][0-9]"},
    "thursday_close": { "type": ["string","null"], "pattern": "^[0-2][0-9]\\:[0-5][0-9]"},
    "friday_open": { "type": ["string","null"], "pattern": "^[0-2][0-9]\\:[0-5][0-9]"},
    "friday_close": { "type": ["string","null"], "pattern": "^[0-2][0-9]\\:[0-5][0-9]"},
    "saturday_open": { "type": ["string","null"], "pattern": "^[0-2][0-9]\\:[0-5][0-9]"},
    "saturday_close": { "type": ["string","null"], "pattern": "^[0-2][0-9]\\:[0-5][0-9]"},
    "sunday_open": { "type": ["string","null"], "pattern": "^[0-2][0-9]\\:[0-5][0-9]"},
    "sunday_close": { "type": ["string","null"], "pattern": "^[0-2][0-9]\\:[0-5][0-9]"},
  }
});

const form_schema = Immutable.fromJS({
  elements: [
    {
      "type": "section",
      "name": "core",
      "elements": [
        { "type": "widget", "widget_type": "new_business_name",  },
        { "type": "field", "field_type": "phone", "property": "mobile", "country_code": "52", "mask": "(##) #### ####",  },
        { "type": "field", "field_type": "phone", "property": "phone", "country_code": "52", "mask": "(##) #### ####",  },
        { "type": "field", "field_type": "email", "property": "email",  },
        { "type": "field", "field_type": "url", "property": "website" },
        { "type": "field", "field_type": "autosuggest", "property": "business_category_id",  }
      ]
    },
    {
      "type": "section",
      "name": "extended",
      "elements": [
        { "type": "field", "field_type": "select", "property": "business_size", "i18n_key": "forms.general.business_size",  },
        { "type": "field", "field_type": "radio", "property": "point_of_sale",  },
        { "type": "field", "field_type": "radio", "property": "wifi",  },
        { "type": "field", "field_type": "radio", "property": "credit_card",  },
        { 
          "type": "section",
          "name": "credit_cards",
          "elements": [
            { "type": "field", "field_type": "checkbox", "property": "credit_card_visa" },
            { "type": "field", "field_type": "checkbox", "property": "credit_card_mc" },
            { "type": "field", "field_type": "checkbox", "property": "credit_card_amex" },
            { "type": "field", "field_type": "checkbox", "property": "credit_card_discover" },
            { "type": "field", "field_type": "checkbox", "property": "credit_card_diners" }
          ],
          "display_if": { "required": ["credit_card"], "properties": { "credit_card": { "enum": [true] } } }
        }
      ]
    },  
    {
      "type": "section",
      "name": "address",
      "elements": [
        { "type": "widget", "widget_type": "address", "country": false, "locale": "en-US",  }
      ]
    },
    {
      "type": "section",
      "name": "hours",
      "elements": [
        { "type": "widget", "widget_type": "operating_hours",  }
      ]
    },
    {
      "type": "section",
      "name": "manager",
      "elements": [
        { "type": "field", "field_type": "text", "property": "executive_first_name",  },
        { "type": "field", "field_type": "text", "property": "executive_middle_name" },
        { "type": "field", "field_type": "text", "property": "executive_last_name",  },
        { "type": "field", "field_type": "text", "property": "executive_title" },
        { "type": "field", "field_type": "select", "property": "executive_gender", "i18n_key": "forms.general",  }
      ]
    }
  ]
})

class PlacesFormPanel extends React.Component {
  render(){
    return (
      <JSONForm
        i18nKey="places.form"
        schema={schema}
        form_schema={form_schema}
        formKey="places/edit"
        onSubmit={this.onSubmit}
        showValidation={true}
      />
    )
  }

  onSubmit = () => {
    const { changes,loadForm } = this.props;
    const body = { data: { type: 'place', attributes: changes }}
    return PlacesActions.update(this.props.place.id,body).then(json => {
      loadForm({'places/edit': json.data.attributes});
    });
  }
}

const mapStateToProps = (state,props) => ({
  place: state.getIn(['places',props.match.params.id]),
  changes: state.getIn(['form_changes','places/edit']),
});

const mapDispatchToProps = {
  loadForm: (data) => ({ type: 'forms.load', data }),
};

export default connect(mapStateToProps,mapDispatchToProps)(PlacesFormPanel);