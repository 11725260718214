import qs from 'qs';
import Immutable from 'immutable';
import * as Sentry from '@sentry/browser';

import { getToken,clearToken } from './authentication_helpers';

export const baseUrl = process.env.REACT_APP_API_URL

export const requestHeaders = () => {
  const h = {
    'Content-Type': 'application/json',
    'Client-Type': 'web',
    'Client-Version': process.env.REACT_APP_RELEASE,
  }
  const token = getToken();
  if(token){ h['Authorization'] = token; }
  return h;
};

export const authorizedFetch = function(url,params,options){
  const q = qs.stringify(params||{});
  const fetchOptions = Immutable.fromJS({
    headers: requestHeaders(),
  }).mergeDeep(options).toJSON();
  return fetch(`${baseUrl}${url}?${q}`,fetchOptions).then(response => {
    if(response.status === 204){
      return {};
    } else if(response.status < 400){
      return response.json();
    } else if(response.status === 401){
      clearToken();
      window.location.href = "/login";
    } else {
      return response.json().then(json => {
        Sentry.captureEvent({
          name: 'RequestError',
          message: `Request to ${url} failed`,
          extra: {
            url,
            params,
            options,
            fetchOptions,
            fetchURL: `${baseUrl}/${url}?${q}`,
            status: response.status,
            body: response.body,
          } 
        });
        throw json;
      });
    }
  })
};

export const parseQuery = function(searchString){
  return qs.parse((searchString || '').replace(/^\?/,''));
}

export const cleanParams = (...paramsSets) => {
  const merged = Object.assign({},...paramsSets);
  return Object.keys(merged).reduce((o,k) => {
    if(merged[k] || merged[k] === 0){ o[k] = merged[k]; }
    return o;
  }, {});
};

export const jsonToRecordAttrs = (klass,json,included=[]) => {
  const attrs = { ...json.attributes, id: json.id };
  return new klass(Immutable.fromJS(attrs));
};

export const cleanBody = body => {
  return Object.keys(body).reduce((params,key) => {
    let v = body[key];
    if(v === ''){ v = null; }
    params[key] = v;
    return params;
  },{});
};
