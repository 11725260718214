
import Immutable from 'immutable';

export const defaults = {
  id: null,
  place_id: null,
  pricing_structure_id: null,
  explorer_project_id: null,
  explorer_id: null,
  lead_id: null,
  data: Immutable.fromJS({}),
  place_data: Immutable.fromJS({}),
  latitude: null,
  longitude: null,
  client: null,
  ip_address: null,
  reported_client: null,
  completed: null,
  not_completed_reason: null,
  created_at: null,
  updated_at: null,
};

class Visit extends Immutable.Record(defaults){

}

export default Visit;