import React from 'react';
import { connect } from 'react-redux';
import GoogleMapsLoader from 'google-maps';


class PlacesMapPanel extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {};
    this.map = React.createRef();
  }

  componentDidMount(){
    GoogleMapsLoader.load((google) => {
      const loc = {lat: this.props.place.latitude, lng: this.props.place.longitude};
      const map = new google.maps.Map(
        this.map.current,
        {zoom: 15, center: loc}
      );

      new google.maps.Marker({position: loc, map: map});
    });
  }

  render(){
    return (
      <div className="w-100" style={{height: '75vh'}} ref={this.map}>        
      </div>
    );
  }
};

const mapStateToProps = (state,initialProps) => {
  return (state) => {
    return {
      place: state.getIn(['places',initialProps.match.params.id]),
    }
  }
};

export default connect(mapStateToProps)(PlacesMapPanel);