import React from 'react';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now'
import differenceInHours from 'date-fns/difference_in_hours'

import ImageMetadataTag from './image_metadata_tag';

class ImageTakenAtMetadata extends React.PureComponent {
  render(){
    const image = this.props.image;
    let text;
    let d;

    if(image.taken_at){
      text = `Taken ${distanceInWordsToNow(image.taken_at)} ago`;
      d = differenceInHours(new Date(),image.taken_at);
    } else {
      text = 'No taken at date';
    }

    let style;
    if(d > 18 || !d){
      style = 'danger';
    } else if(d > 6){
      style = 'warning'
    } else {
      style = 'success';
    }
    

    return (
      <ImageMetadataTag text={text} bstyle={style}/>
    )
  }
}

export default ImageTakenAtMetadata;