import Immutable from 'immutable';

const defaults = {
  id: null,
  email: null,
  token: null,
  first_name: null,
  last_name: null,
  created_at: null,
  updated_at: null,
  suspended_at: null,
  regions: [],
};

class Validator extends Immutable.Record(defaults){

}

export default Validator;