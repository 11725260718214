import React from 'react';
import cn from 'classnames';
import { Link,withRouter } from 'react-router-dom';
import { UncontrolledDropdown,DropdownToggle,DropdownItem,DropdownMenu } from 'reactstrap';
import { isLoggedIn,clearToken } from '../../shared/helpers/authentication_helpers';

class Navbar extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {show: false};
  }

  render(){
    let logout;
    if(isLoggedIn()){
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      logout = <a className="nav-link" href="#" onClick={this.signOut}>Sign Out</a>
    }

    let collapseClasses = cn("collapse navbar-collapse",{show: this.state.show})
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <Link className="navbar-brand" to="/explorers">dataPlorAdmin</Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={this.toggle}>
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={collapseClasses} id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <span className="text-primary">Explorers</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link className="nav-link text-primary" to="/explorers">Table</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link className="nav-link text-primary" to="/explorers_payments">Payments</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link className="nav-link text-primary" to="/explorers_quotas">Quotas</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <span className="text-success">Places</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link className="nav-link text-primary" to="/places">Table</Link>
                </DropdownItem>
                 <DropdownItem>
                  <Link className="nav-link text-primary" to="/deduplicator">Deduplicator</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <span className="text-visit">Visits</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link className="nav-link text-primary" to="/visits">Table</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <span className="text-lead">Leads</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link className="nav-link text-primary" to="/leads">Table</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <span className="text-danger">Projects</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link className="nav-link text-primary" to="/projects">Table</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </ul>


          {logout}
        </div>
      </nav>
    )
  }

  toggle = (e) => {
    this.setState((prevState) => ({show: !prevState.show}) )
  }

  signOut = () => {
    clearToken();
    this.props.history.push('/signin')
  }
}

export default withRouter(Navbar);


