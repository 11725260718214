import GoogleMapsLoader from 'google-maps';

GoogleMapsLoader.KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;
GoogleMapsLoader.VERSION = 3.37;
GoogleMapsLoader.LIBRARIES = ['places'];

export const loadMaps = () => {
  return new Promise((resolve,reject) => {
    GoogleMapsLoader.load(google => {
      resolve(google);
    });
  })
}