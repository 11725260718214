import React from 'react';

import SessionsActions from '../actions/sessions_actions';

import { isLoggedIn } from '../../shared/helpers/authentication_helpers';

class SignIn extends React.PureComponent {

  constructor(props){
    super(props);
    this.email = React.createRef();
    this.password = React.createRef();
    this.state = {}
  }

  componentDidMount(){

    if(isLoggedIn()){
      this.props.history.push('/');
    }
  }

  render(){
    let errors;
    if(this.state.errors){
      errors = <div className="invalid-feedback" style={{display: 'block'}}>{this.state.errors}</div>
    }

    const pageStyle = {
      backgroundImage: 'url("https://source.unsplash.com/1600x900/?travel")',
      backgroundSize: 'cover',
      height: 'calc(100vh - 56px)',
      width: '100vw',
      overflow: 'hidden'
    }

    return (
      <div style={pageStyle}>
        <div className="container" >
          <div className="row justify-content-center" >
            <div className="col-lg-4 col">

              <div className="card mt-5">
                <div className="card-body">
                  <h5 className="card-title">Sign in</h5>
                  <div className="form-group">
                    {errors}
                  </div>
                  <form onSubmit={this.onSubmit}>
                    <div className="form-group">
                      <label htmlFor="emailInput">Email address</label>
                      <input type="email" className="form-control" id="emailInput" ref={this.email}/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="passwordInput">Password</label>
                      <input type="password" className="form-control" id="passwordInput" ref={this.password}  />
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </form>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    )
  }

  onSubmit = (e) => {
    e.preventDefault();
    const params = {
      email: this.email.current.value,
      password: this.password.current.value
    };
    return SessionsActions.create(params).then(() => {
      this.props.history.push('/');
    }).catch(resp => {
      return resp.json().then(json => {
        if(json.errors && json.errors[0]){
          this.setState({errors: json.errors[0].detail});
        }
      })
    });
  }

}

export default SignIn;