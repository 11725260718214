import React from 'react';
import { Link } from 'react-router-dom';

import LiveDate from './live_date';

import ExplorerProjectsActions from '../actions/explorer_projects_actions';

class ProjectsExplorerRow extends React.PureComponent {

  render(){

    const { explorer_project } = this.props;

    let disabled_at;
    if(explorer_project.disabled_at){
      disabled_at = <LiveDate date={explorer_project.disabled_at} />
    } else {
      disabled_at = <button className="btn btn-danger btn-sm py-0" onClick={this.disable}>Disable</button>
    }

    return (
      <tr>
        <td><Link to={`/explorers/${explorer_project.explorer_id}/edit`} className="badge badge-primary">{explorer_project.explorer_id}</Link></td>
        <td>{explorer_project.first_name}</td>
        <td>{explorer_project.last_name}</td>
        <td><LiveDate date={explorer_project.created_at} /></td>
        <td><LiveDate date={explorer_project.training_completed_at} /></td>
        <td><LiveDate date={explorer_project.quiz_completed_at} /></td>
        <td className="text-center">{disabled_at}</td>
      </tr>
    )
  }

  disable = () => {
    ExplorerProjectsActions.update(this.props.explorer_project.id,{
      data: {
        attributes: {
          disabled_at: (new Date()).toISOString()
        }
      }
    });
  }
}

ProjectsExplorerRow.columns = [
  { col: "explorer_id", label: "Id" },
  { col: "first_name", label: "First" },
  { col: "last_name", label: "Last" },
  { col: "created_at", label: "Joined" },
  { col: "training_completed_at", label: "Training" },
  { col: "quiz_completed_at", label: "Quiz" },
  { col: "disabled_at", label: "Disabled" },
];

export default ProjectsExplorerRow;