import Store from '../reducers/store';

import { includedToActions } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';


const dispatchResource = json => {
  if(json.included){
    includedToActions(json.included).forEach(action => {
      Store.dispatch(action);
    }); 
  }
  Store.dispatch({type: 'places.merge', data: [json.data] });
  return json;
}

export default {
  index: function(params){
    const q = Object.assign({sort: 'places.id', sort_dir: 'desc'},params,{page_size: 25});
    return authorizedFetch('/v1/admin/places',q).then(json => {
      
      if(json && json.meta){
        Store.dispatch({type: 'page.merge', data: {totals: {places: json.meta.total}}})  
      }
      if(json && json.data){
        Store.dispatch({type: 'places.load', ...json});
      }
      return json;
    });
  },
  show: function(id){
    return authorizedFetch(`/v1/admin/places/${id}`).then(dispatchResource)
  },
  update: function(id,params){
    const opts = {
      method: 'PUT', 
      body: JSON.stringify(params)
    };
    return authorizedFetch(`/v1/admin/places/${id}`,null,opts).then(dispatchResource);
  }
};