import { authorizedFetch } from '../../shared/helpers/request_helpers';

export default {
  create: function(visit_id,params){
    const opts = {
      method: 'POST', 
      body: JSON.stringify({ data: { type: 'upload_credential', attributes: params}})
    };
    return authorizedFetch(`/v1/admin/visits/${visit_id}/upload_credentials`,null,opts);
  },
}