import React from 'react';
import Immutable from 'immutable';

import JSONForm from '../../shared/components/json_form';

import notFoundForm from '../../shared/data/not_found_form';
import notFoundSchema from '../../shared/data/not_found_schema';


class NotFoundVisitsForm extends React.PureComponent {

  constructor(props){
    super(props);
    this.state = {
    };
  }
  
  componentDidMount(){
    this.validationChecker = setInterval(this.checkPendingValidations,4000);
  }

  componentWillUnmount(){
    clearInterval(this.validationChecker)
  }

  render(){
    const {formKey,i18nKey,visit} = this.props;
    const { canEdit } = this.state;

    let reason;

    if(visit.getIn(['response','data','attributes','not_found'])){
      reason = <h4 className="text-danger">Lead was not found</h4>
    } else if(visit.getIn(['response','data','attributes','permanently_closed'])){
      reason = <h4 className="text-danger">Lead was permanently_closed</h4>
    }

    return (
      <React.Fragment>
        <div className="visits-form mb-2 ">
          <h4 className="text-danger">Visit not completed!</h4>
          {reason}
          <p>{visit.getIn(['response','data','attributes','notes'])}</p>
        </div>
      </React.Fragment>
    );
  }

  onPhotoUploadSelect = (params) => {
    return Promise.resolve();
  }

  toggleEdit = () => {
    this.setState(prevState => ({canEdit: !prevState.canEdit}))
  }

}


export default NotFoundVisitsForm;

