import React from 'react';
import { Route } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux';

import Store from '../reducers/store';

import AuthenticatedRoute from './authenticated_route';
import Navbar from './navbar';
import ExplorersIndex from './explorers_index';
import ExplorersEdit from './explorers_edit';
import ExplorersPaymentsIndex from './explorers_payments_index';
import ExplorersQuotasIndex from './explorers_quotas_index';
import PlacesIndex from './places_index';
import PlacesEdit from './places_edit';
import VisitsIndex from './visits_index';
import VisitsEdit from './visits_edit';
import LeadsIndex from './leads_index';
import LeadsEdit from './leads_edit';
import ProjectsIndex from './projects_index';
import ProjectsEdit from './projects_edit';
import SignIn from './sign_in';
import DuplicateChecksIndex from './duplicate_checks_index';

import AppI18nProvider from '../../shared/components/app_i18n_provider';

import BusinessCategoryActions from '../actions/business_category_actions';

import { loadMaps } from '../helpers/google_helpers';

class App extends React.Component {

  componentDidMount(){
    loadMaps();
    BusinessCategoryActions.index('en');
  }

  render() {
    return (
      <Provider store={Store}>
        <AppI18nProvider locale="en">
          <BrowserRouter>
            <div id="adminApp" className="App">
              <Navbar />
              <Route exact path="/signin" component={SignIn} />
              <div className="container-fluid mt-2">
                <AuthenticatedRoute exact path="/" component={ExplorersIndex} />
                <AuthenticatedRoute path="/explorers/:id" component={ExplorersEdit} />
                <AuthenticatedRoute exact path="/explorers" component={ExplorersIndex} />
                <AuthenticatedRoute exact path="/explorers_payments" component={ExplorersPaymentsIndex} />
                <AuthenticatedRoute exact path="/explorers_quotas" component={ExplorersQuotasIndex} />
                <AuthenticatedRoute path="/places/:id" component={PlacesEdit} />
                <AuthenticatedRoute exact path="/places" component={PlacesIndex} />
                <AuthenticatedRoute path="/visits/:id" component={VisitsEdit} />
                <AuthenticatedRoute exact path="/visits" component={VisitsIndex} />
                <AuthenticatedRoute path="/leads/:id" component={LeadsEdit} />
                <AuthenticatedRoute exact path="/leads" component={LeadsIndex} />
                <AuthenticatedRoute path="/projects/:id" component={ProjectsEdit} />
                <AuthenticatedRoute exact path="/projects" component={ProjectsIndex} />
                <AuthenticatedRoute exact path="/deduplicator" component={DuplicateChecksIndex} />
              </div>
            </div>
          </BrowserRouter>
        </AppI18nProvider>
      </Provider>
    );
  }
}

export default App;
