import Immutable from 'immutable';

const defaults = {
  id: null,
  original_id: null,
  possible_duplicate_id: null,
  admin_id: null,
  was_duplicate: null,
  checked_at: null,
  created_at: null,
  updated_at: null,
};


class DuplicateCheck extends Immutable.Record(defaults){

}


export default DuplicateCheck;