import Store from '../reducers/store';

import { includedToActions } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';


const dispatchResource = json => {
  if(json.included){
    includedToActions(json.included).forEach(action => {
      Store.dispatch(action);
    }); 
  }
  Store.dispatch({type: 'duplicate_checks.merge', data: [json.data] });
  return json;
}

export default {
  create: function(params){
    const opts = {
      method: 'POST', 
      body: JSON.stringify(params)
    };
    return authorizedFetch(`/v1/deduplicator/duplicate_checks`,null,opts).then(dispatchResource);
  },
  update: function(id,params){
    const opts = {
      method: 'PUT', 
      body: JSON.stringify(params)
    };
    return authorizedFetch(`/v1/deduplicator/duplicate_checks/${id}`,null,opts);
  }
};