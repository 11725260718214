import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Switch,Route,Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import PlacesActions from '../actions/places_actions';

import PlacesFormPanel from './places_form_panel';
import PlacesVisitsPanel from './places_visits_panel';
import PlacesImagesPanel from './places_images_panel';
import PlacesMapPanel from './places_map_panel';
import LiveDate from './live_date';

class PlacesEdit extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = { 
      place: {},
      tab: 'details',
    };
  }

  componentDidMount(){
    PlacesActions.show(this.props.match.params.id).then(json => {
      this.props.loadForm({'places/edit': json.data.attributes});
      this.setState({loaded: true});
    })
  }

  render(){
    if(this.state.loaded && this.props.business_categories_loaded){
      const place = this.props.place;
      const subpath = this.props.location.pathname.match(/places\/\d+\/(\w+)/)[1];

      let explorerLink;
      if(this.props.explorer_project){
        explorerLink = (
          <span>Explored by <Link to={`/explorers/${this.props.explorer_project.explorer_id}/edit`}>
            <span className="badge badge-primary">{this.props.explorer_project.explorer_id}</span>
          </Link></span>
        );
      }

      let validatorLink;
      if(this.props.place_validation){
        const pv = this.props.place_validation;
        if(pv.validated_at){
          validatorLink = (
            <p>
              <span className="h3"><span className={cn({'badge-success': pv.validated, 'badge-danger': !pv.validated},'badge')}>{pv.validated ? 'Validated' : 'Rejected'}</span></span>
              <span className="ml-2">by <span className="badge badge-info">{pv.validator_id}</span></span>
              <span className="ml-2"><LiveDate date={pv.validated_at}/></span>
              { pv.overridden_at ? <span className="">, Overrode <LiveDate date={pv.overridden_at} /></span> : null }
            </p>
          )
        } else {
          validatorLink = (
            <p>
              <span className="h3"><span className="badge badge-secondary">Pending</span></span>
              <span className="ml-2">assigned to <span className="badge badge-info">{pv.validator_id}</span></span>
              <span className="ml-2"><LiveDate date={pv.created_at}/></span>
            </p>
          );
        }
      }

      return (
        <div id="placesShow" className="mb-5">
          <div className="row mt-3">
            <div className="col">
              <h2><span className="badge badge-success">{this.props.place.id}</span> {this.props.place.name}</h2>
              {validatorLink}
              <p>{explorerLink}, Added <LiveDate date={this.props.place.created_at} />, updated <LiveDate date={this.props.place.updated_at} /></p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <div className="card">
                <div className="card-header">
                  <ul className="nav nav-tabs card-header-tabs">
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'edit')})} to={`/places/${place.id}/edit`}>Edit</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'visits')})} to={`/places/${place.id}/visits`}>Visits</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'images')})} to={`/places/${place.id}/images`}>Images</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'map')})} to={`/places/${place.id}/map`}>Map</Link>
                    </li>

                  </ul>
                </div>
                <div className="card-body">
                  <Switch>
                    <Route exact path="/places/:id/edit" component={PlacesFormPanel} />
                    <Route exact path="/places/:id/visits" component={PlacesVisitsPanel} />
                    <Route exact path="/places/:id/images" component={PlacesImagesPanel} />
                    <Route exact path="/places/:id/map" component={PlacesMapPanel} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return 'loading...'
    }
    
  }

  onSubmit = (update) => {
    
  }


  switchTab = (e) => {
    e.preventDefault();
    this.setState({tab: e.target.getAttribute('data-tab')})
  }
}

const mapStateToProps = (state,props) => {
  let explorer_project;
  let place_validation;
  const placeId = parseInt(props.match.params.id,10);
  const visit = state.get('visits').find(v => v.place_id === placeId);
  if(visit){
    explorer_project = state.get('explorer_projects').find(xp => xp.id === visit.explorer_project_id);
    place_validation = state.get('place_validations').find(pv => pv.visit_id === visit.id);
  }

  return {
    place: state.getIn(['places',props.match.params.id]),
    explorer_project: explorer_project,
    place_validation: place_validation,
    business_categories_loaded: !isEmpty(state.get('business_categories'))
  }
}

const mapDispatchToProps = {
  loadForm: (data) => ({ type: 'forms.load', data }),
};

export default connect(mapStateToProps,mapDispatchToProps)(PlacesEdit);
