import Immutable from 'immutable';

const defaults = {
  id: null,
  name: null,
  description: null,
  short_description: null,
  instructions: null,
  created_at: null,
  updated_at: null,
  key: null,
  closed: null,
  disabled: null,
  disabled_at: null,
  training: null,
  quiz: null,
  schema: null,
  form_schema: null,
  current_pricing_structure_id: null,
  schema_key: null,
  geofence: null,
  accepts_new: null,
  region_ids: [],
};

class Project extends Immutable.Record(defaults){

}

export default Project;