import Store from '../reducers/store';

import { includedToActions } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';

const dispatchResource = json => {
  if(json.included){
    includedToActions(json.included).forEach(action => {
      Store.dispatch(action);
    }); 
  }
  Store.dispatch({type: 'projects.merge', data: [json.data] });
  return json;
}

export default {
  index: function(params){
    const q = Object.assign({sort: 'id', sort_dir: 'desc'},params,{page_size: 25});
    return authorizedFetch('/v1/admin/projects',q).then(json => {
      if(json.included){
        includedToActions(json.included).forEach(action => {
          Store.dispatch(action);
        }); 
      }
      if(json && json.meta){
        Store.dispatch({type: 'page.merge', data: {totals: {projects: json.meta.total}}})  
      }
      if(json && json.data){
        return Store.dispatch({type: 'projects.load', ...json});
      }
    });
  },
  show: function(id){
    return authorizedFetch(`/v1/admin/projects/${id}`).then(dispatchResource);
  },
  update: function(id,params){
    const opts = {
      method: 'PUT', 
      body: JSON.stringify(params)
    };
    return authorizedFetch(`/v1/admin/projects/${id}`,null,opts).then(dispatchResource);
  }
};