import Immutable from 'immutable';

const defaults = {
  id: null,
  explorer_id: null,
  amount: null,
  vendor_id: null,
  completed_at: null,
  address: null,
  created_at: null,
  updated_at: null,
  number: null,
  batch_id: null,
  vendor_batch_id: null,
  currency: null,
  notes: null,
};

class Payment extends Immutable.Record(defaults){

}

export default Payment;