import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';

import DuplicateChecksActions from '../actions/duplicate_checks_actions';

import DuplicatePlaceComparisonPanel from './duplicate_place_comparison_panel';
import DuplicatePlaceMap from './duplicate_place_map';

class DuplicateChecksIndex extends React.PureComponent {

  componentDidMount(){
    DuplicateChecksActions.create();
  }

  render(){
    if(this.props.check){
      return (
        <div id="duplicateChecksIndex" className="pt-4">
          <div className="row mb-4">
            <div className="col">
              <DuplicatePlaceMap original={this.props.original} possible_duplicate={this.props.possible_duplicate} />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-4 mx-auto">
              <button className="col btn btn-danger" onClick={this.onDuplicate}>Duplicate</button>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-4 mx-auto">
              <button className="col btn btn-success" onClick={this.onDifferent}>Different (OK)</button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <DuplicatePlaceComparisonPanel place={this.props.original} bsClass="primary"/>
            </div>
            <div className="col">
              <DuplicatePlaceComparisonPanel place={this.props.possible_duplicate} bsClass="warning"/>
            </div> 
          </div>
        </div>
      )
    } else {
      return <FontAwesomeIcon icon={faSpinner} spin className="mt-5 mx-auto d-block" size="lg"/>
    }
  }

  onDuplicate = (e) => {
    return this.updateCheck(true);
  }

  onDifferent = (e) => {
    return this.updateCheck(false);
  }

  updateCheck = (was_duplicate) => {
    const id = this.props.check.id
    const body = { data: { id: id, attributes: { was_duplicate: was_duplicate} }};
    return DuplicateChecksActions.update(id,body).then(() => {
      this.props.clearCheck()
      return DuplicateChecksActions.create();
    })
  }
}

const mapStateToProps = state => {
  const check = state.get('duplicate_checks').first();
  let original;
  let possible_duplicate;
  if(check){
    original = state.getIn(['places',check.original_id.toString()]);
    possible_duplicate = state.getIn(['places',check.possible_duplicate_id.toString()]);
  }
  return {
    check,
    original,
    possible_duplicate,
  }
};

const mapDispatchToProps = {
  clearCheck: () => ({type: 'duplicate_checks.load', data: []}),
}

export default connect(mapStateToProps,mapDispatchToProps)(DuplicateChecksIndex);