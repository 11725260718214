import React from 'react';
import { connect } from 'react-redux';

import JSONForm from '../../shared/components/json_form';

import VisitsActions from '../actions/visits_actions';
import UploadCredentialsActions from '../actions/upload_credentials_actions';


class VisitsFormPanel extends React.Component {

  render(){
    const { project } = this.props;

    return (
      <div className="visits-form mb-2">
        <JSONForm
          i18nKey="visits.form"
          schema={project.schema}
          form_schema={project.form_schema}
          formKey="visits/edit"
          sendRemoteValidation={this.sendRemoteValidation}
          onPhotoUploadSelect={this.onPhotoUploadSelect}
          onSubmit={this.onSubmit}
          showValidation={true}
        />
      </div>
    )
  }

  onSubmit = () => {
    const { changes,loadForm } = this.props;
    const body = { data: { type: 'visit', attributes: changes }}
    return VisitsActions.update(this.props.visit.id,body).then(json => {
      loadForm({'visits/edit': json.data.attributes.response.data.attributes});
    })
  }

  onPhotoUploadSelect = (params) => {
    const { visit } = this.props;
    return UploadCredentialsActions.create(visit.id,params);
  }
}

const mapStateToProps = (state,props) => {
  const visit = state.getIn(['observations',props.match.params.id]);
  const project_visit = state.get('project_visits').filter(pv => pv.observation_id === visit.id).minBy(pv => pv.id);
  const project = state.getIn(['projects',project_visit.project_id.toString()]);
  return {
    visit: visit,
    project: project,
    changes: state.getIn(['form_changes','visits/edit']),
  };
};

const mapDispatchToProps = {
  loadForm: (data) => ({ type: 'forms.load', data }),
};

export default connect(mapStateToProps,mapDispatchToProps)(VisitsFormPanel);