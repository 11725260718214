import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import isAfter from 'date-fns/is_after';
import subDays from 'date-fns/sub_days';

import LiveDate from './live_date';

import { regionToFlag } from '../../shared/helpers/emoji_helpers';

class ExplorersRow extends React.PureComponent {

  render(){
    const e = this.props.explorer

    const rowClasses = cn({
      'table-danger': this.props.explorer.suspended_at,
      'table-success': !this.props.explorer.notes && e.quiz_completed_at && isAfter(e.quiz_completed_at, subDays(new Date(),1))
    })

    return (
      <tr className={rowClasses}>
        <td><Link to={`/explorers/${e.id}/edit`} className="badge badge-primary">{e.id}</Link></td>
        <td>{regionToFlag(e.region_id)}</td>
        <td>{e.email}</td>
        <td>{e.referral_code}</td>
        <td>{e.first_name}</td>
        <td>{e.last_name}</td>
        <td>{e.city}</td>
        <td>{e.state}</td>
        <td>{e.country}</td>
        <td><LiveDate date={e.created_at} /></td>
        <td>{e.training_completed_at ? <LiveDate date={e.training_completed_at}/> : null}</td>
        <td>{e.quiz_completed_at ? <LiveDate date={e.quiz_completed_at}/> : null}</td>
        <td>{e.visits_count}</td>
      </tr>
    )
  }
}

export default ExplorersRow;