import Store from '../reducers/store';

import { authorizedFetch } from '../../shared/helpers/request_helpers';

export default {
  index: function(params={}){
    const q = Object.assign(params,{page_size: 25});
    return authorizedFetch('/v1/admin/validations',q).then(json => {
      if(json && json.data){
        Store.dispatch({type: 'validations.load', ...json});
      }
      return json;
    });
  },
};