import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import GoogleMapsLoader from 'google-maps';
import debounce from 'lodash/debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';

import VisitsActions from '../actions/visits_actions';

class VisitsMapPanel extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {};
    this.map = React.createRef();
  }

  render(){
    const { visit } = this.props;

    return (
      <div>
        <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${visit.latitude},${visit.longitude}`}>
          <button className="btn btn-primary" >Click here for map</button>
        </a>
      </div>
    )
  }
};

//   componentDidMount(){
//     GoogleMapsLoader.load((google) => {
//       const loc = {lat: this.props.visit.latitude, lng: this.props.visit.longitude};
//       const lats = [];
//       const longs = [];

//       const map = new google.maps.Map(
//         this.map.current      );

//       // Visit
//       const visitMarker = new google.maps.Marker({position: loc, map: map});
//       this.setState({visitMarker})
      
//       lats.push(loc.lat);
//       longs.push(loc.lng);

//       // Images
//       this.props.images.forEach(image => {
//         const label = image && image.role && image.role[0].toUpperCase();
//         new google.maps.Marker({
//           position: {lat: image.latitude, lng: image.longitude},
//           label: label,
//           map: map
//         });

//         lats.push(image.latitude);
//         longs.push(image.longitude);
//       });

//       // Lead
//       if(this.props.lead){

//         new google.maps.Marker({
//           position: {lat: this.props.lead.latitude, lng: this.props.lead.longitude},
//           label: 'L',
//           map: map
//         });
//         lats.push(this.props.lead.latitude);
//         longs.push(this.props.lead.longitude);
//       }

//       map.fitBounds({
//         north: Math.max(...lats.filter(x => x)),
//         east:  Math.max(...longs.filter(x => x)),
//         west:  Math.min(...longs.filter(x => x)),
//         south: Math.min(...lats.filter(x => x)) 
//       });

//       // map.addListener('bounds_changed',debounce((() => this.updateCenter(map.getCenter())),200));

//     });


//   }

//   render(){
//     const { lat,lng,editing,submitting } = this.state;

//     let button;
//     if(editing){
//       let content;
//       if(submitting){
//         content = <FontAwesomeIcon icon={faSpinner} spin className="mt-5 mx-auto d-block" size="lg"/>
//       } else {
//         content = <span>Save location ({lng.toFixed(6)},{lat.toFixed(6)})</span>
//       }

//       button = <button className="btn btn-success col" onClick={this.onSubmit}>{content}</button>
//     } else {
//       button = <button className="btn btn-primary col" onClick={this.onEdit}>Change location</button>
      
//     }

//     const mapClasses = cn({
//       "google-map-edit": this.state.editing
//     },'w-100');

//     return (
//       <div>
//         <div className="row mb-2 justify-content-md-center">
//           <div className="col-5">
//             {button}
//           </div>
//         </div>
//         <div className="row">
//           <div className="col">
//             <div className={mapClasses} style={{height: '75vh'}} ref={this.map}>        
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }

//   onEdit = (e) => {
//     this.setState({editing: true});
//   }

//   onSubmit = (e) => {
//     const {lat,lng,visitMarker} = this.state
    
//     const body = { data: { type: 'visit', attributes: {coordinates_json: {type: "Point", coordinates: [lng,lat]}} }}
//     return VisitsActions.update(this.props.visit.id,body).then(() => {
//       visitMarker.setPosition({lng,lat})
//       this.setState({submitting: false, editing: false}) 
//     });
//   }

//   updateCenter = (latlon) => {
//     this.setState(latlon.toJSON())
//   }
// };

const mapStateToProps = (state,initialProps) => {
  return (state) => {
    const visitId = initialProps.match.params.id;
    const visit = state.getIn(['observations',visitId]);

    let lead;
    if(visit.lead_id){
      lead = state.getIn(['leads',visit.lead_id.toString()]);
    }

    return {
      visit: visit,
      images: state.get('images').filter(i => i.observation_id === parseInt(visitId,10)),
      lead: lead
    }
  }
};

export default connect(mapStateToProps)(VisitsMapPanel);