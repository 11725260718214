import React from 'react';
import { withGoogleMap } from "react-google-maps";

import { loadMaps } from '../helpers/google_helpers';

class DuplicateMapWrapper extends React.Component {
  constructor(props){
    super(props);
    this.state = {loaded: false};
    loadMaps().then(() => this.setState({loaded: true}))
  }

  shouldComponentUpdate(nextProps,nextState){
    if(this.state.loaded !== nextState.loaded){
      return true
    } else {
      return false;
    }
  }
  
  render(){
    if(this.state.loaded){
      const MapWrapper = withGoogleMap(props => {
        return this.props.children
      });

      return (
        <MapWrapper
          containerElement={<div className="col" style={{height: '400px'}}/>}
          mapElement={<div style={{ height: `100%` }} />}
        /> 
      );
    } else {
      return null;
    }
  }

}

export default DuplicateMapWrapper;