import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck,faTimes,faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import LiveDate from './live_date';

class PlacesRow extends React.PureComponent {

  render(){
    let gmb;
    if(this.props.place.gmb_interested){
      gmb = <FontAwesomeIcon icon={faCheck} className="mx-auto d-block" size="lg" color="green"/>
    } else {
      gmb = <FontAwesomeIcon icon={faTimes} className="mx-auto d-block" size="lg" color="gray"/>
    }

    let validated;
    if(!this.props.place.validated_at){
      validated = <FontAwesomeIcon icon={faCircleNotch} spin className="mx-auto d-block" size="lg" color="gray"/>
    } else if(this.props.place.validated){
      validated = <FontAwesomeIcon icon={faCheck} className="mx-auto d-block" size="lg" color="green"/>
    } else {
      validated = <FontAwesomeIcon icon={faTimes} className="mx-auto d-block" size="lg" color="red"/>
    }

    return (
      <tr>
        <td><Link to={`/places/${this.props.place.id}/edit`} className="badge badge-success">{this.props.place.id}</Link></td>
        <td>{this.props.place.name}</td>
        <td>{validated}</td>
        <td>{gmb}</td>
        <td>{this.props.place.city}</td>
        <td>{this.props.place.state}</td>
        <td>{this.props.place.country}</td>
        <td><Link to={`/explorers/${this.props.place.explorer_id}/edit`} className="badge badge-primary">{this.props.place.explorer_id}</Link></td>
        <td><LiveDate date={this.props.place.created_at} /></td>
      </tr>
    )
  }
}

export default PlacesRow;