import React from 'react';
import { connect } from 'react-redux';

import VisitsActions from '../actions/visits_actions';

import IndexView from './index_view';
import VisitsRow from './visits_row';

class VisitsIndex extends IndexView {

  constructor(props){
    super(props);
    this.state = {};
  }

  render(){
    const { total,page_size } = this.state;
    const { page_number } = this.parsedQuery();

    const rows = this.props.visits.toIndexedSeq().map(visit => {
      return <VisitsRow key={visit.id} visit={visit} />
    })

    return (
      <div id="visits_index">
        <div className="row mb-2 justify-content-end">
          <div className="col-md-4">
            {this.searchBar()}
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <table className="table table-striped table-bordered table-sm table-hover">
              <thead>
                <tr>
                  {this.generateHeaders(VisitsRow.columns)}
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
            {this.pagination({
              page_number: (page_number || 1),
              total,
              page_size,
            })}
          </div>
        </div>
      </div>
    )
  }

  fetchData = (query) => {
    return VisitsActions.index(query).then(response => {
      this.setState({total: response.meta.total, page_size: response.meta.page_size})
    });
  }
}

const mapStateToProps = state => {
  return {
    visits: state.get('visits'),
    total: state.getIn(['page','totals','visits']),
  }
}

export default connect(mapStateToProps)(VisitsIndex);