import { createStore } from 'redux';
import { combineReducers } from 'redux-immutable';

import Place from '../../shared/models/place';
import VisitObservation from '../../shared/models/visit_observation';
import Image from '../../shared/models/image';
import ExplorerProject from '../../shared/models/explorer_project';
import Prevalidation from '../../shared/models/prevalidation';
import Project from '../../shared/models/project';
import Lead from '../../shared/models/lead';
import ProjectVisit from '../../shared/models/project_visit';

import validatorReducer from './validator_reducer';
import placeValidationReducer from './place_validation_reducer';

import { 
  createResourceReducer,
  createFormReducer,
  createFormChangesReducer,
  createFormErrorsReducer,
  createBasicReducer,
  createVanillaReducer,
} from '../../shared/helpers/reducer_helpers';

const formsDefaults = {
  'visits/edit': { },
};

const reducers = combineReducers({
  place_validation:    placeValidationReducer,
  places:              createResourceReducer(Place,'places','place'),
  visits:              createResourceReducer(VisitObservation,'visit_observations','visit_observation'),
  duplicate_visits:    createResourceReducer(VisitObservation,'duplicate_visits','duplicate_visit'),
  images:              createResourceReducer(Image,'images','image'),
  project_visits:      createResourceReducer(ProjectVisit,'project_visits','project_visit'),
  projects:            createResourceReducer(Project,'projects','project'),
  leads:               createResourceReducer(Lead,'leads','lead'),
  prevalidations:      createResourceReducer(Prevalidation,'prevalidations','prevalidation'),
  page:                createBasicReducer('page'),
  forms:               createFormReducer(formsDefaults),
  form_changes:        createFormChangesReducer(formsDefaults),
  form_errors:         createFormErrorsReducer(formsDefaults),
  form_async_errors:   createFormErrorsReducer(formsDefaults,'form_async_errors'),
  form_async_warnings: createFormErrorsReducer(formsDefaults,'form_async_warnings'),
  business_categories: createVanillaReducer('business_categories',{}),
  validator:           validatorReducer,
});

export default createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
