import React from 'react';
import GoogleMapsLoader from 'google-maps';

class PlaceValidationsEditMediaColumn extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {};
    this.streetView = React.createRef();
    this.map = React.createRef();
  }

  componentDidMount(){
    GoogleMapsLoader.load((google) => {
      const coords = {lat: this.props.visit.latitude, lng: this.props.visit.longitude};
      new google.maps.StreetViewPanorama(this.streetView.current,{
        position: coords,
        zoom: 1
      });

      const map = new google.maps.Map(this.map.current, {
        zoom: 19,
        center: coords,
      });

      new google.maps.Marker({
        position: coords,
        map: map
      });

      new google.maps.Circle({
        strokeWeight: 0,
        fillColor: '#000000',
        fillOpacity: 0.25,
        map: map,
        center: coords,
        radius: this.props.visit.getIn(['place_data','location_accuracy'])
      });

      if(this.props.lead){
        new google.maps.Marker({
          position: {lat: parseFloat(this.props.lead.latitude), lng: parseFloat(this.props.lead.longitude)},
          label: "L",
          map: map
        });
      }

      this.props.images.forEach(i => {
        if(i.latitude && i.longitude){
          const imgCoords = {lat: i.latitude, lng: i.longitude };
          new google.maps.Marker({
            position: imgCoords,
            label: (i.role[0].toUpperCase()),
            map: map
          });

          var imgLine = [
            coords,
            imgCoords,
          ];

          new google.maps.Polyline({
            path: imgLine,
            geodesic: true,
            strokeColor: '#000000',
            strokeOpacity: 1.0,
            strokeWeight: 2,
            map: map
          });

          if(i.location_accuracy){
            new google.maps.Circle({
              strokeWeight: 0,
              fillColor: '#000000',
              fillOpacity: 0.25,
              map: map,
              center: imgCoords,
              radius: i.location_accuracy
            });
          }
        }
      });

      this.props.possible_matches.toIndexedSeq().forEach((pm,i) => {
        new google.maps.Marker({
          position: {lat: pm.latitude, lng: pm.longitude},
          label: (i+1).toString(),
          map: map
        });
      })
    });
  }

  render(){

    return (
      <div className="h-100">
        <div className="h-50" ref={this.streetView}></div>
        <div className="h-50" ref={this.map}></div>
      </div>
    )
  }
}

export default PlaceValidationsEditMediaColumn;