// #  id                   :bigint(8)        not null, primary key
// #  type                 :text
// #  source               :text
// #  observer_type        :string
// #  observer_id          :bigint(8)
// #  observable_type      :string
// #  observable_id        :bigint(8)
// #  parent_id            :bigint(8)
// #  data                 :jsonb
// #  input                :jsonb
// #  response             :jsonb
// #  coordinates          :geography({:srid point, 4326
// #  requested_at         :datetime
// #  responded_at         :datetime
// #  reviewed_at          :datetime
// #  validated            :boolean
// #  validated_at         :datetime
// #  uuid                 :uuid
// #  pricing_structure_id :bigint(8)
// #  lead_id              :bigint(8)
// #  created_at           :datetime         not null
// #  updated_at           :datetime         not null


import Immutable from 'immutable';

export const defaults = {
  id: null,
  type: null,
  source: null,
  uuid: null,
  pricing_structure_id: null,
  observer_id: null,
  observer_type: null,
  observable_id: null,
  observable_type: null,
  parent_id: null,
  lead_id: null,
  data: Immutable.fromJS({}),
  input: Immutable.fromJS({}),
  response: Immutable.fromJS({}),
  validated: null,
  latitude: null,
  longitude: null,
  created_at: null,
  updated_at: null,
  validated_at: null,
  responded_at: null,
  requested_at: null,
};

class Observation extends Immutable.Record(defaults){

}

export default Observation;