import Immutable from 'immutable';

const defaults = {
  id: null,
  key: null,
  description: null,
  amount: null,
  currency: 'MXN',
  pricing_structure_id: null,
  recurring: true,
};

class PricingItem extends Immutable.Record(defaults){

}

export default PricingItem;