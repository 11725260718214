import React from 'react';
import { Route } from 'react-router';
import { BrowserRouter,Switch } from 'react-router-dom'
import { Provider } from 'react-redux';

import Store from '../reducers/store';

import AuthenticatedRoute from '../router/authenticated_route';
import UnauthenticatedRoute from '../router/unauthenticated_route';
import NoMatch from '../router/no_match';

import ExplorerI18nProvider from './explorer_i18n_provider';

import Navbar from './navbar';
import Notifications from './notifications';
import Login from './login';
import RegistrationsNew from './registrations_new';
import PasswordResetsNew from './password_resets_new';
import PasswordResetsEdit from './password_resets_edit';
import OnboardingsStart from './onboardings_start';
import OnboardingFormViewsShow from './onboarding_form_views_show';
import AccountsNew from './accounts_new';
import TrainingsShow from './trainings_show';
import QuizzesShow from './quizzes_show';
import QuizReportsShow from './quiz_reports_show';
import DashboardsIndex from './dashboards_index';
import VisitsIndex from './visits_index';
import AccountsEdit from './accounts_edit';
import HoldingTank from './holding_tank';
import IntercomMessenger from './intercom_messenger';
import GoogleAnalytics from './google_analytics';

import { loadMaps } from '../../shared/helpers/google_maps_helpers';
import { isLoggedIn } from '../../shared/helpers/authentication_helpers';

import AccountActions from '../actions/account_actions';

class App extends React.Component {
  componentDidMount(){
    loadMaps(); // Start loading right away
    if(isLoggedIn()){ AccountActions.show() };
  }

  render() {
    return (
      <Provider store={Store}>
        <ExplorerI18nProvider>
          <BrowserRouter>
            <div id="explorerApp" className="app">
              <Navbar />
              <Notifications />
              <IntercomMessenger />
              <GoogleAnalytics />
              <div className="container mt-4 pb-3">
                <Switch>
                  <UnauthenticatedRoute exact path="/login" component={Login} />
                  <UnauthenticatedRoute exact path="/register" component={RegistrationsNew} />
                  <UnauthenticatedRoute exact path="/request_password" component={PasswordResetsNew} />
                  <UnauthenticatedRoute exact path="/reset_password/:token" component={PasswordResetsEdit} />
                  <AuthenticatedRoute exact path="/start" component={OnboardingsStart} />
                  <AuthenticatedRoute exact path="/onboarding_form" component={OnboardingFormViewsShow} />
                  <AuthenticatedRoute path="/onboarding_form/:view_name" component={OnboardingFormViewsShow} />
                  <AuthenticatedRoute exact path="/introduce" component={AccountsNew} />
                  <AuthenticatedRoute exact path="/training" component={TrainingsShow} />
                  <AuthenticatedRoute exact path="/training/:step" component={TrainingsShow} />
                  <AuthenticatedRoute exact path="/quiz" component={QuizzesShow} />
                  <AuthenticatedRoute exact path="/quiz/complete" component={QuizReportsShow} />
                  <AuthenticatedRoute exact path="/" component={DashboardsIndex} />
                  <AuthenticatedRoute exact path="/visits" component={VisitsIndex} />
                  <AuthenticatedRoute exact path="/account" component={AccountsEdit} />
                  <AuthenticatedRoute exact path="/coming_soon" component={HoldingTank} />
                  <Route component={NoMatch} />
                </Switch>
              </div>
            </div>
          </BrowserRouter>
        </ExplorerI18nProvider>
      </Provider>
    );
  }
}

export default App;
