import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch,faCheck,faTimes } from '@fortawesome/pro-light-svg-icons';

import LiveDate from './live_date';

class VisitsRow extends React.PureComponent {

  render(){

    let validated;
    if(this.props.visit.validated === null){
      validated = <FontAwesomeIcon icon={faCircleNotch} spin className="mx-auto d-block" size="lg" color="gray"/>
    } else if(this.props.visit.validated){
      validated = <FontAwesomeIcon icon={faCheck} className="mx-auto d-block" size="lg" color="green"/>
    } else {
      validated = <FontAwesomeIcon icon={faTimes} className="mx-auto d-block" size="lg" color="red"/>
    }

    const projectLinks = this.props.visit.project_ids.map(project_id => {
      return <Link key={project_id} to={`/projects/${project_id}/edit`} className="badge badge-danger ml-1">{project_id}</Link>
    })

    return (
      <tr>
        <td><Link to={`/visits/${this.props.visit.id}/edit`} className="badge badge-visit">{this.props.visit.id}</Link></td>
        <td>{validated}</td>
        <td>{this.props.visit.name}</td>
        <td>{this.props.visit.city}</td>
        <td>{this.props.visit.state}</td>
        <td>{this.props.visit.country}</td>
        <td><Link to={`/explorers/${this.props.visit.explorer_id}/edit`} className="badge badge-primary">{this.props.visit.explorer_id}</Link></td>
        <td>{projectLinks}</td>
        <td><LiveDate date={this.props.visit.created_at} /></td>
      </tr>
    )
  }
}

VisitsRow.columns = [
  { col: "id", label: "Id" },
  { col: "validated", label: "Valid"},
  { col: "name", label: "Name" },
  { col: "city", label: "City" },
  { col: "state", label: "State" },
  { col: "country", label: "Country" },
  { col: "explorer_id", label: "Explorer" },
  { col: "project_id", label: "Project" },
  { col: "created_at", label: "Created at" },
];

export default VisitsRow;