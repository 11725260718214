import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import ImageWithMetadata from '../../shared/components/image_with_metadata';

const assetsServer = process.env.REACT_APP_ASSETS_SERVER;

class DuplicatePlaceComparisonPanel extends React.PureComponent {

  render(){
    const { place,bsClass } = this.props;
    const extKey = place.exterior_photo && place.exterior_photo.replace(/\.\w+$/,'');
    const intKey = place.interior_photo && place.interior_photo.replace(/\.\w+$/,'');

    let extImage;
    if(extKey){ 
      /* eslint-disable jsx-a11y/aria-role */
      extImage = <ImageWithMetadata assetsServer={assetsServer} urlKey={extKey} role="exterior" /> 
      /* eslint-enable jsx-a11y/aria-role */
    } else {
      extImage = <div className="bg-light border border-secondary col d-flex justify-content-center align-items-center rounded" style={{height: '150px'}}>No exterior</div>
    }

    let intImage;
    if(intKey){ 
      /* eslint-disable jsx-a11y/aria-role */
      intImage = <ImageWithMetadata assetsServer={assetsServer} urlKey={intKey} role="interior" /> 
      /* eslint-enable jsx-a11y/aria-role */
    } else {
      intImage = <div className="bg-light border border-secondary col d-flex justify-content-center align-items-center rounded" style={{height: '150px'}}>No interior</div>
    }



    return (
      <div className={cn("card",`border-${bsClass}`)}>
        <div className="card-body">
          <div className="row mb-2">
            <div className="col-6 mx-auto">
              {extImage}
            </div>
            <div className="col-6 mx-auto">
              {intImage}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>{place.name}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>{place.street_number} {place.street_name}</td>
                  </tr>
                  <tr>
                    <td>Neighborhood</td>
                    <td>{place.neighborhood}</td>
                  </tr>
                  <tr>
                    <td>City</td>
                    <td>{place.city}</td>
                  </tr>
                  <tr>
                    <td>State</td>
                    <td>{place.state}</td>
                  </tr>
                  <tr>
                    <td>Zip</td>
                    <td>{place.postal_code}</td>
                  </tr>
                  <tr>
                    <td>Country</td>
                    <td>{place.country}</td>
                  </tr>
                  <tr>
                    <td>Coords</td>
                    <td>{place.longitude} {place.latitude}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{place.email}</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>{place.phone}</td>
                  </tr>
                  <tr>
                    <td>Mobile</td>
                    <td>{place.mobile}</td>
                  </tr>
                  <tr>
                    <td>Executive</td>
                    <td>{[place.executive_first_name,place.executive_middle_name,place.executive_last_name].filter(x => x).join(" ")}</td>
                  </tr>
                  <tr>
                    <td>Ex. Title</td>
                    <td>{place.executive_title}</td>
                  </tr>
                  <tr>
                    <td colSpan="2" className="text-center">
                      <Link className={`btn btn-${bsClass} btn-lg`} to={`/places/${place.id}/edit`} target="_blank">Go to places/{place.id}</Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// const mapStateToProps = state => {
//   const check = state.get('duplicate_checks').first();
//   return {
//     check: check,
//     original: state.getIn(['places',check.original_id.toString()]),
//     possible_duplicate: state.getIn(['places',check.possible_duplicate_id.toString()])
//   }
// }

export default connect()(DuplicatePlaceComparisonPanel);