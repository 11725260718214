import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import GoogleMapsLoader from 'google-maps';
import debounce from 'lodash/debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';

import LeadsActions from '../actions/leads_actions';

class LeadsMapPanel extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {};
    this.map = React.createRef();
  }

  componentDidMount(){
    console.log(this.props.lead.toJSON())
    GoogleMapsLoader.load((google) => {
      const loc = {lat: this.props.lead.latitude, lng: this.props.lead.longitude};

      const map = new google.maps.Map(
        this.map.current,
        {zoom: 15, center: loc}      
      );

      // lead
      const leadMarker = new google.maps.Marker({position: loc, map: map});
      this.setState({leadMarker})

      map.addListener('bounds_changed',debounce((() => this.updateCenter(map.getCenter())),200));

    });


  }

  render(){
    const { lat,lng,editing,submitting } = this.state;

    let button;
    if(editing){
      let content;
      if(submitting){
        content = <FontAwesomeIcon icon={faSpinner} spin className="mt-5 mx-auto d-block" size="lg"/>
      } else {
        content = <span>Save location ({lng.toFixed(6)},{lat.toFixed(6)})</span>
      }

      button = <button className="btn btn-success col" onClick={this.onSubmit}>{content}</button>
    } else {
      button = <button className="btn btn-primary col" onClick={this.onEdit}>Change location</button>
      
    }

    const mapClasses = cn({
      "google-map-edit": this.state.editing
    },'w-100');

    return (
      <div>
        <div className="row mb-2 justify-content-md-center">
          <div className="col-5">
            {button}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className={mapClasses} style={{height: '75vh'}} ref={this.map}>        
            </div>
          </div>
        </div>
      </div>
    );
  }

  onEdit = (e) => {
    this.setState({editing: true});
  }

  onSubmit = (e) => {
    const {lat,lng,leadMarker} = this.state
    
    const body = { data: { type: 'visit', attributes: {coordinates_json: {type: "Point", coordinates: [lng,lat]}} }}
    return LeadsActions.update(this.props.lead.id,body).then(() => {
      leadMarker.setPosition({lng,lat})
      this.setState({submitting: false, editing: false}) 
    });
  }

  updateCenter = (latlon) => {
    this.setState(latlon.toJSON())
  }
};

const mapStateToProps = (state,initialProps) => {
  return (state) => {
    const leadId = initialProps.match.params.id;
    const lead = state.getIn(['leads',leadId]);

    return {
      lead: lead
    }
  }
};

export default connect(mapStateToProps)(LeadsMapPanel);