import Immutable from 'immutable';
import { defaults as visit_defaults } from '../../shared/models/visit';

export const defaults = Object.assign({},visit_defaults,{
  number: null,
  name: null,
  city: null,
  state: null,
  country: null,
  timezone: null,
  timezone_offset: null,
  reported_at: null,
  created_at: null,
  updated_at: null,
  project_id: null,
  validated: null,
  project_ids: [],
});


class AdminVisit extends Immutable.Record(defaults){

}


export default AdminVisit;