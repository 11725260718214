import Store from '../reducers/store';

import { includedToActions } from '../../shared/helpers/action_helpers';
import { authorizedFetch } from '../../shared/helpers/request_helpers';

const dispatchResource = json => {
  if(json.included){
    includedToActions(json.included).forEach(action => {
      Store.dispatch(action);
    }); 
  }
  Store.dispatch({type: 'explorer_projects.merge', data: [json.data] });
  return json;
}

export default {
  index: function(params){
    const q = Object.assign({sort: 'explorer_projects.id', sort_dir: 'desc'},params,{page_size: 25});
    return authorizedFetch('/v1/admin/explorer_projects',q).then(json => {
      if(json && json.data){
        Store.dispatch({type: 'explorer_projects.load', ...json});
      }
      return json;
    });
  },
  create: function(params){
    const opts = {
      method: 'POST', 
      body: JSON.stringify(params)
    };
    return authorizedFetch(`/v1/admin/explorer_projects`,null,opts).then(dispatchResource);
  },
  update: function(id,params){
    const opts = {
      method: 'PUT', 
      body: JSON.stringify(params)
    };
    return authorizedFetch(`/v1/admin/explorer_projects/${id}`,null,opts).then(dispatchResource);
  }
};